.coming-soon-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, sans-serif;
  color: #333;
}

.hero-section {
  text-align: center;
  padding: 4rem 0;
  background: linear-gradient(135deg, #f8f9fa 0%, #e9ecef 100%);
  border-radius: 20px;
  margin-bottom: 4rem;
}

.hero-section h1 {
  font-size: 3.5rem;
  color: #212529;
  margin-bottom: 1rem;
  animation: fadeInUp 0.8s ease;
}

.tagline {
  font-size: 1.8rem;
  color: #495057;
  margin-bottom: 1.5rem;
  animation: fadeInUp 0.8s ease 0.2s backwards;
}

.launch-date {
  font-size: 1.4rem;
  color: #868e96;
  font-weight: 500;
  animation: fadeInUp 0.8s ease 0.4s backwards;
}

.app-preview {
  margin: 3rem auto;
  max-width: 300px;
  animation: float 6s ease-in-out infinite;
}

.phone-mockup {
  position: relative;
  padding: 20px;
}

.preview-image {
  width: 100%;
  height: auto;
  border-radius: 20px;
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.1);
}

.features-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 2rem;
  margin: 4rem 0;
}

.feature-card {
  padding: 2rem;
  background: white;
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
  transition: transform 0.3s ease;
}

.feature-card:hover {
  transform: translateY(-5px);
}

.feature-icon {
  font-size: 2.5rem;
  margin-bottom: 1rem;
}

.feature-card h3 {
  color: #212529;
  margin-bottom: 0.5rem;
  font-size: 1.4rem;
}

.feature-card p {
  color: #6c757d;
  line-height: 1.6;
}

.newsletter-section {
  text-align: center;
  padding: 4rem 0;
  background: linear-gradient(135deg, #e9ecef 0%, #dee2e6 100%);
  border-radius: 20px;
  margin: 4rem 0;
}

.newsletter-section h2 {
  font-size: 2.5rem;
  color: #212529;
  margin-bottom: 1rem;
}

.signup-form {
  display: flex;
  max-width: 500px;
  margin: 2rem auto;
  gap: 1rem;
}

.signup-form input {
  flex: 1;
  padding: 1rem;
  border: 2px solid #ced4da;
  border-radius: 8px;
  font-size: 1rem;
  transition: border-color 0.3s ease;
}

.signup-form input:focus {
  outline: none;
  border-color: #495057;
}

.signup-form button {
  padding: 1rem 2rem;
  background: #212529;
  color: white;
  border: none;
  border-radius: 8px;
  font-size: 1rem;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.signup-form button:hover {
  background: #343a40;
}

.success-message {
  background: #d4edda;
  color: #155724;
  padding: 1rem;
  border-radius: 8px;
  animation: fadeIn 0.5s ease;
}

.social-proof {
  text-align: center;
  margin: 4rem 0;
}

.countdown {
  display: flex;
  justify-content: center;
  gap: 4rem;
}

.count-item {
  text-align: center;
}

.number {
  display: block;
  font-size: 3rem;
  font-weight: bold;
  color: #212529;
  margin-bottom: 0.5rem;
}

.label {
  color: #6c757d;
  font-size: 1rem;
}

.coming-soon-footer {
  text-align: center;
  padding: 2rem 0;
  border-top: 1px solid #dee2e6;
}

.social-links {
  display: flex;
  justify-content: center;
  gap: 2rem;
  margin-top: 1rem;
}

.social-links a {
  color: #495057;
  text-decoration: none;
  transition: color 0.3s ease;
}

.social-links a:hover {
  color: #212529;
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes float {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-20px);
  }
}

@media (max-width: 768px) {
  .coming-soon-container {
    padding: 1rem;
  }
  
  .hero-section h1 {
    font-size: 2.5rem;
  }
  
  .tagline {
    font-size: 1.4rem;
  }
  
  .signup-form {
    flex-direction: column;
    padding: 0 1rem;
  }
  
  .countdown {
    gap: 2rem;
  }
  
  .number {
    font-size: 2rem;
  }
}

.app-store-button {
  display: inline-block;
  transition: transform 0.2s ease;
}

.app-store-button:hover {
  transform: scale(1.05);
}

.app-store-badge {
  height: 40px;
  width: auto;
}

.app-store-preorder {
  margin-top: 1.5rem;
  padding-top: 1.5rem;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.app-store-preorder p {
  margin-bottom: 1rem;
  color: #495057;
  font-size: 1.1rem;
} 