.download-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, sans-serif;
}

.download-hero {
  text-align: center;
  padding: 4rem 0;
  background: linear-gradient(135deg, #f8f9fa 0%, #e9ecef 100%);
  border-radius: 20px;
  margin-bottom: 3rem;
}

.download-hero h1 {
  font-size: 3rem;
  color: #212529;
  margin-bottom: 1rem;
}

.tagline {
  font-size: 1.5rem;
  color: #495057;
  margin-bottom: 2rem;
}

.app-store-section {
  margin: 2rem 0;
}

.app-store-button {
  display: inline-block;
  transition: transform 0.2s ease;
}

.app-store-button:hover {
  transform: scale(1.05);
}

.app-store-badge {
  height: 48px;
}

.features-section {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
  margin: 4rem 0;
}

.feature {
  padding: 2rem;
  background: white;
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.feature h3 {
  color: #212529;
  margin-bottom: 1rem;
  font-size: 1.5rem;
}

.feature p {
  color: #6c757d;
  line-height: 1.6;
}

.cta-section {
  text-align: center;
  padding: 4rem 0;
  background: linear-gradient(135deg, #e9ecef 0%, #dee2e6 100%);
  border-radius: 20px;
  margin-top: 3rem;
}

.cta-section h2 {
  font-size: 2rem;
  color: #212529;
  margin-bottom: 1rem;
}

.cta-section p {
  color: #495057;
  font-size: 1.2rem;
  max-width: 600px;
  margin: 0 auto;
}

.download-footer {
  text-align: center;
  padding: 2rem 0;
  margin-top: 3rem;
  border-top: 1px solid #dee2e6;
}

.privacy-link {
  color: #6c757d;
  text-decoration: none;
  font-size: 0.9rem;
  transition: color 0.2s ease;
}

.privacy-link:hover {
  color: #212529;
  text-decoration: underline;
}

@media (max-width: 768px) {
  .download-hero h1 {
    font-size: 2rem;
  }
  
  .tagline {
    font-size: 1.2rem;
  }
  
  .features-section {
    grid-template-columns: 1fr;
  }
  
  .cta-section h2 {
    font-size: 1.5rem;
  }
  
  .cta-section p {
    font-size: 1rem;
  }
} 