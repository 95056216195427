.privacy-container {
  max-width: 1000px;
  margin: 0 auto;
  padding: 2rem;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, sans-serif;
  color: #333;
  line-height: 1.6;
}

.privacy-content {
  background: white;
  padding: 2rem;
  border-radius: 12px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.privacy-container h1 {
  font-size: 2.5rem;
  color: #212529;
  margin-bottom: 0.5rem;
  text-align: center;
}

.last-updated {
  color: #6c757d;
  text-align: center;
  margin-bottom: 3rem;
  font-style: italic;
}

section {
  margin-bottom: 2.5rem;
}

h2 {
  color: #212529;
  font-size: 1.75rem;
  margin-bottom: 1rem;
  padding-bottom: 0.5rem;
  border-bottom: 2px solid #e9ecef;
}

h3 {
  color: #343a40;
  font-size: 1.25rem;
  margin: 1.5rem 0 1rem 0;
}

ul {
  padding-left: 1.5rem;
  margin-bottom: 1.5rem;
}

li {
  margin-bottom: 0.5rem;
}

p {
  margin-bottom: 1rem;
}

.contact-info {
  background: #f8f9fa;
  padding: 1.5rem;
  border-radius: 8px;
  margin-top: 1rem;
}

.contact-info p {
  margin-bottom: 0.5rem;
}

@media (max-width: 768px) {
  .privacy-container {
    padding: 1rem;
  }
  
  .privacy-content {
    padding: 1.5rem;
  }
  
  .privacy-container h1 {
    font-size: 2rem;
  }
  
  h2 {
    font-size: 1.5rem;
  }
  
  h3 {
    font-size: 1.15rem;
  }
} 